<template>
    <div class="nodes">
        <div class="line-item" v-for="(o, i) in list" :key="i">
            <span class="sp-icon"> <a-icon type="user" /></span>
            <a-input placeholder="请输入" v-model="o.name" readOnly class="input-dom input-dom-edit" />
            <a-button type="link" icon="form" class="edit-icon" @click="editDom(o, i)" />
            <span class="zusi" v-if="i == 0">（注：点击可修改名称）</span>
            <a-button type="link" icon="delete" @click="delDom(i)" v-if="i > 0" />
            <div class="left-line flex0">
                <div class="tag-box flex-wrap">
                    <div v-for="(o1, i1) in o.val" :key="i1" class="flex0">
                        <a-tag color="blue" class="nodeTags flex0">
                            <OpenData :type="o1.type" :openid="o1.userId || o1.id" :corpid="o1.corpId"></OpenData>
                        </a-tag>
                        <!-- <a-icon type="arrow-right" class="arrow-right" /> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="line-item">
            <span class="sp-icon"> <a-icon type="plus" /></span>
            添加节点
            <a-button type="primary" shape="circle" icon="plus" @click="addDom" />
            <div class="left-line flex0"></div>
        </div>
        <div class="line-item" v-if="isReceiveVisebel">
            <span class="sp-icon"> <a-icon type="user-add" /></span>
            接收人
            <div class="left-line flex0">
                <div class="tag-box flex-wrap">
                    <div v-for="(o1, i1) in mainList" :key="i1" class="flex0">
                        <a-tag color="blue" class="nodeTags flex0">
                            <OpenData :type="o1.type" :openid="o1.userId || o1.id" :corpid="o1.corpId"></OpenData>
                            <a-button type="link" icon="close" @click="mainList.splice(i1, 1)" />
                        </a-tag>
                        <a-icon type="arrow-right" class="arrow-right" />
                    </div>
                    <a-button
                        type="primary"
                        icon="plus"
                        ghost
                        class="plus-bt font"
                        @click="showSelctDialog('mainList', 2, null, allList)"
                    >
                    </a-button>
                </div>
            </div>
        </div>
        <div class="line-item" v-if="isCcVisebel">
            <span class="sp-icon"> <a-icon type="user-add" /></span>
            抄送人
            <div class="left-line left-line-last flex0">
                <div class="tag-box flex-wrap">
                    <div v-for="(o1, i1) in csr_list" :key="i1" class="flex0">
                        <a-tag color="blue" class="nodeTags flex0">
                            <OpenData :type="o1.type" :openid="o1.userId || o1.id" :corpid="o1.corpId"></OpenData>
                            <a-button type="link" icon="close" @click="csr_list.splice(i1, 1)" />
                        </a-tag>
                        <a-icon type="arrow-right" class="arrow-right" />
                    </div>
                    <a-button
                        type="primary"
                        icon="plus"
                        ghost
                        class="plus-bt font"
                        @click="showSelctDialog('csr_list', 2, null, allList)"
                    >
                    </a-button>
                </div>
            </div>
        </div>
        <nodeAdd ref="nodeAdd" @selectPersons="selectPersons"></nodeAdd>
        <selectPersonDialog ref="selectPersonDialog" @selectTodoMsg="selectTodoMsg" />
    </div>
</template>
<script>
import { selectPerson } from '@/mixins/index'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonDialog from '@/views/fileStream/documentManage/weixin/selectPersonDialog'
import nodeAdd from './nodeAdd.vue'
export default {
    components: {
        selectPersonDialog,
        nodeAdd,
        OpenData,
    },
    props: {
        // 接收人
        isReceiveVisebel: {
            type: Boolean,
            default: true,
        },
        // 抄送人
        isCcVisebel: {
            type: Boolean,
            default: true,
        },
    },
    mixins: [selectPerson],
    data() {
        return {
            list: [{ name: '领导审批', val: [], countersign: 1 }],
            csr_list: [],
            mainList: [],
        }
    },
    computed: {
        allList() {
            let _list = this.list.map((item) => item.val)
            return [...this.mainList, ..._list, ...this.csr_list]
        },
    },
    methods: {
        updataMessage() {
            this.$emit('change', {
                mainList: this.mainList,
                list: this.list,
                csrList: this.csr_list,
            })
        },
        selectPersons(evt) {
            const {
                nodeMess: { index },
                values,
            } = evt
            const obj = {
                name: values.name,
                countersign: values.countersign,
                val: JSON.parse(values.person),
            }
            this.$set(this.list, index, obj)
            this.updataMessage()
        },

        getDataType({ deptId }) {
            return deptId ? 'departmentName' : 'userName'
        },
        initData({ mainList, list, csrList }) {
            this.mainList = mainList
            this.list = list
            this.csr_list = csrList
        },
        reset() {
            this.mainList = []
            this.list = [{ name: '领导审批', val: [], countersign: 1 }]
            this.csr_list = []
        },
        addDom() {
            this.list.push({ name: '节点', val: [] })
        },
        editDom(ele, index) {
            this.$refs.nodeAdd.open({
                el: ele,
                index,
                allList: this.allList || [],
            })
        },
        delDom(i) {
            this.list.splice(i, 1)
        },
    },
}
</script>
<style lang="less" scoped>
.flex {
    display: flex;
    justify-content: space-between;
}
.flex0 {
    display: flex;
    align-items: center;
}
.font {
    font-size: 16px;
}

.my-form {
    // width: 478px;
    margin-left: 10px;
    background: #fff;
    padding: 20px 0;
}
::v-deep .ant-form-item-label {
    text-align: left;
}

.line {
    height: 1px;
    border: 1px dashed #3b9afa;
    display: block;
    width: 100%;
    margin: 30px 0;
}
.plus-bt {
    width: 46px;
    height: 46px;
    background: #e9f2fe;
    border-radius: 4px;
    margin: 10px 0;
}
.left-line::before {
    content: '';
    height: 80px;
    position: relative;
    top: 0px;
    left: 13px;
    width: 2px;
    background: #cdcfd1;
}
.left-line-last:before {
    background: white;
}
.sp-icon {
    width: 26px;
    height: 26px;
    background: #cdcfd1;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: white;
    line-height: 26px;
}
.submit-bt {
    text-align: right;
    margin: 40px 0 40px;
    button {
        // width: 100px;
        height: 36px;
        background: #3b9afa;
        border-radius: 4px;
        margin-right: 10px;
    }
}
.edit-icon {
    margin-left: 10px;
}
.zusi {
    font-size: 12px;
    color: #acacac;
}
.input-dom {
    width: 100px;
    margin-left: 6px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.65);
}
.input-dom-edit {
    border: none;
}
.input-dom-edit:hover {
    border: none;
}
.input-dom-edit:focus {
    border: none;
    box-shadow: none;
}
.nodeTags {
    height: 46px;
    border: none;
    margin-bottom: 2px;
    button {
        margin: 0;
    }
}
.flex-wrap {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.tag-box {
    margin: 10px 0 10px 30px;
}
.arrow-right {
    font-size: 0.6em;
    color: #aaa;
    margin-right: 10px;
}
::v-deep .ant-form-extra {
    margin-top: 5px;
}
.moreTemp {
    cursor: pointer;
    color: #3b9afa;
}
</style>
