<template>
  <div>
    <a-steps direction="vertical" :current="-1" size="small">
      <a-step v-for="el in nodes" :key="el.id">
        <template #title>
          <div style="padding-bottom: 12px;">
            {{ el.name }}
          </div>
        </template>
        <template #description>
          <a-tag color="blue" v-for="(i, index) in el.userList" :key="index">
            <OpenData :type="getDataType(i)" :openid="i.deptId || i.userId" :corpid="i.corpId"></OpenData>
          </a-tag>
        </template>
      </a-step>
    </a-steps>
  </div>
</template>
<script>
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
export default {
  components: {
    OpenData
  },
  props: {
    nodes: {
      type: Array,
      default() {
        return []
      }
    }
  },
  methods: {
    getDataType({ deptId }) {
      return deptId ? 'departmentName' : 'userName'
    }
  }
}
</script>
